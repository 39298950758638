import React, { createContext } from "react";
import PropTypes from "prop-types";

export const RoutesContext = createContext();

RoutesContextProvider.propTypes = {
    routes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

export default function RoutesContextProvider({ routes, children }) {
    return <RoutesContext.Provider value={{ routes }}>{children}</RoutesContext.Provider>;
}
