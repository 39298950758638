import PropTypes from "prop-types";
import cc from "classcat";
import React, { useEffect, useState } from "react";

Fade.propTypes = {
    in: PropTypes.bool.isRequired,
    fadeSpeed: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    onAfterShow: PropTypes.func,
    initiallyShown: PropTypes.bool,
};

export default function Fade({ in: _in, fadeSpeed, children, onAfterShow, initiallyShown = false }) {
    const [show, setShow] = useState(initiallyShown);

    useEffect(() => {
        if (_in && !show) {
            setShow(true);
            setTimeout(() => onAfterShow && onAfterShow(), fadeSpeed);
        } else if (!_in) {
            setShow(false);
        }
    }, [_in]);

    return (
        <div
            className={cc([
                "relative z-50 transition-all",
                { "visible opacity-100": show, "invisible opacity-0": !show },
            ])}
            style={{ transitionDuration: `${fadeSpeed}ms` }}>
            {children}
        </div>
    );
}
