import React from "react";
import PropTypes from "prop-types";
import cc from "classcat";

Spinner.propTypes = {
    variant: PropTypes.oneOf(["primary", "secondary", "danger"]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default function Spinner({ variant = "primary", size = "medium" }) {
    // TODO sizes + colors (used in button also based on the button color!!) + inside a container/card
    //  https://preline.co/docs/spinners.html#customized-description
    return (
        <span
            className={cc([
                "mr-1 mt-0.5 inline-block animate-spin rounded-full border-[3px] border-current border-t-transparent",
                { "h-4 w-4": size === "small" },
                { "h-6 w-6 ": size === "medium" },
                { "h-8 w-8 ": size === "large" },
                { "text-indigo-500": variant === "primary" },
                { "text-gray-500": variant === "secondary" },
                { "text-blue-600": variant === "soft" },
                { "text-red-600": variant === "danger" },
            ])}
            role="status"
            aria-label="loading">
            <span className="sr-only">Loading...</span>
        </span>
    );
}
