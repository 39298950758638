import { isAfter } from "date-fns/isAfter";
import { addSeconds } from "date-fns/addSeconds";

export default function cacheProvider() {
    function getLocalStorageItem(key) {
        return JSON.parse(window.localStorage.getItem(key));
    }

    return {
        has(key) {
            const item = getLocalStorageItem(key);
            if (!item) {
                return false;
            }

            if (item.expiration > 0 && isAfter(new Date(), new Date(item.expiration))) {
                window.localStorage.removeItem(key);
                return false;
            }

            return true;
        },
        get(key) {
            if (!this.has(key)) {
                return null;
            }

            return getLocalStorageItem(key).value;
        },
        set(key, value, seconds = 0) {
            return window.localStorage.setItem(
                key,
                JSON.stringify({
                    value,
                    expiration: seconds > 0 ? addSeconds(new Date(), seconds) : seconds,
                }),
            );
        },
        remove(key) {
            window.localStorage.removeItem(key);
        },
    };
}
