import { captureException } from "@sentry/react";
import { CanceledError } from "axios";

export default function handleReportError(error) {
    console.error(error);

    if (error.name === "ChunkLoadError" || error.name === "SyntaxError") {
        window.location.reload();
        return;
    }

    if (
        error instanceof CanceledError ||
        (error.response && (error.response.status === 403 || error.response.status === 503))
    ) {
        return;
    }

    captureException(error);
}
