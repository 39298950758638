import React from "react";
import { Link as _Link, useResolvedPath } from "react-router-dom";
import PropTypes from "prop-types";
import { stringify } from "qs";
import useGeneratePath from "./generatePath";

Link.propTypes = {
    to: PropTypes.string.isRequired,
    params: PropTypes.object,
    query: PropTypes.object,
};

export default function Link({ to, params = {}, query = {}, ...props }) {
    const resolvedPath = useResolvedPath();
    const generatePath = useGeneratePath();
    const search = stringify(query);
    const pathname = generatePath(to, params);

    return (
        <_Link
            {...props}
            to={{ pathname, search }}
            onClick={(e) => {
                if (resolvedPath.pathname === pathname && resolvedPath.search === search) {
                    e.preventDefault();
                }
            }}
        />
    );
}
