import React, { useContext } from "react";
import {
    DropdownMenuContent as DropdownPrimitiveMenuContent,
    Portal as DropdownPrimitivePortal,
} from "@radix-ui/react-dropdown-menu";
import PropTypes from "prop-types";
import cc from "classcat";
import { twMerge } from "tailwind-merge";
import { DropdownContext } from "./Dropdown";

DropdownContent.propTypes = {
    children: PropTypes.node.isRequired,
    side: PropTypes.oneOf(["top", "left", "bottom", "right"]),
    sideOffset: PropTypes.number,
    align: PropTypes.oneOf(["start", "center", "end"]),
    alignOffset: PropTypes.number,
    className: PropTypes.string,
};

export default function DropdownContent({ children, side, sideOffset, align, alignOffset, className }) {
    const { fadeIn } = useContext(DropdownContext);

    return (
        <DropdownPrimitivePortal>
            <DropdownPrimitiveMenuContent
                loop
                side={side}
                sideOffset={sideOffset}
                align={align}
                alignOffset={alignOffset}
                onCloseAutoFocus={(e) => e.preventDefault()}
                className={twMerge(
                    cc([
                        "z-50",
                        "absolute",
                        "right-0",
                        "mt-2",
                        "w-48",
                        "origin-top-right",
                        "rounded-md",
                        "bg-white",
                        "py-1",
                        "shadow-lg",
                        "ring-1",
                        "ring-black",
                        "ring-opacity-5",
                        "focus:outline-none",
                        {
                            "scale-1 origin-[var(--radix-dropdown-menu-content-transform-origin)] opacity-100": fadeIn,
                        },
                        { "scale-0 opacity-0": !fadeIn },
                        className,
                    ]),
                )}>
                <ul>{children}</ul>
            </DropdownPrimitiveMenuContent>
        </DropdownPrimitivePortal>
    );
}
