import React, { useState, createContext, useContext } from "react";
import PropTypes from "prop-types";
import FullPageLoader from "./FullPageLoader";

const ApplicationLoaderContext = createContext(null);

export function useApplicationLoaderContext() {
    return useContext(ApplicationLoaderContext);
}

ApplicationLoaderProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function ApplicationLoaderProvider({ children }) {
    const [isPageLoaderShown, setIsPageLoaderShown] = useState(true);

    return (
        <ApplicationLoaderContext.Provider value={{ setIsPageLoaderShown }}>
            <FullPageLoader open={isPageLoaderShown} />
            {children}
        </ApplicationLoaderContext.Provider>
    );
}
