import React from "react";
import { Trans as I18nextTrans, useTranslation } from "react-i18next";

Trans.propTypes = {};

export default function Trans(props) {
    useTranslation();

    return <I18nextTrans {...props} />;
}
