import { createReducer } from "@reduxjs/toolkit";
import { memoize } from "proxy-memoize";

/*******************
 * ACTION TYPES
 *******************/
const actionTypes = {
    SET_CURRENT_LISTING_LOADER: "listingFactory/SET_CURRENT_LISTING_LOADER",
};

/*******************
 * REDUCER
 *******************/
export default createReducer({ currentListingLoader: null }, (builder) => {
  builder.addCase(actionTypes.SET_CURRENT_LISTING_LOADER, (state, action) => {
    return { ...state, currentListingLoader: action.payload };
  });
});

/*******************
 * ACTIONS
 *******************/
export const listFactoryActions = {
    setCurrentListingLoader: function (listingLoader) {
        return { type: actionTypes.SET_CURRENT_LISTING_LOADER, payload: listingLoader };
    },
};

/*******************
 * SELECTORS
 *******************/

export const listFactorySelectors = {
    getCurrentListingLoader: memoize((state) => state.listFactory.currentListingLoader),
};
