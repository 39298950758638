import React from "react";
import cc from "classcat";
import PropTypes from "prop-types";
import Spinner from "./Spinner";

CentralLoader.propTypes = {
    show: PropTypes.bool,
    size: PropTypes.number,
    className: PropTypes.string,
};

export default function CentralLoader({ show = true, className }) {
    return (
        <div
            className={cc([
                "absolute left-0 top-0 z-10 h-full w-full bg-black bg-opacity-10 transition",
                { "invisible opacity-0": !show, "opacity-1": show },
                className,
            ])}>
            <div className="flex h-full items-center justify-center">
                <Spinner size="large" variant="primary" />
            </div>
        </div>
    );
}
