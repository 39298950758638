import React, { useEffect } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import PropTypes from "prop-types";
// hooks
import { useApplicationLoaderContext } from "../ApplicationLoaderContext";

PublicLayout.propTypes = {
    children: PropTypes.node,
};

export default function PublicLayout({ children = null }) {
    const { setIsPageLoaderShown } = useApplicationLoaderContext();

    const navigation = useNavigation();

    useEffect(() => {
        if (navigation.state === "loading") {
            setIsPageLoaderShown(true);
        } else {
            setIsPageLoaderShown(false);
        }

        return () => setIsPageLoaderShown(true);
    }, [navigation.state]);

    return <main className="flex h-full min-h-screen items-center justify-center">{children || <Outlet />}</main>;
}
