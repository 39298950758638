import React from "react";
import { TooltipProvider as TooltipPrimitiveProvider } from "@radix-ui/react-tooltip";
import PropTypes from "prop-types";

TooltipProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function TooltipProvider({ children }) {
    return <TooltipPrimitiveProvider skipDelayDuration={0}>{children}</TooltipPrimitiveProvider>;
}
