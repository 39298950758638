import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authSelectors } from "../../redux/auth";
import Trans from "../intl/Trans";
import PrivateLayout from "../app/Layout/PrivateLayout";
import PublicLayout from "../app/Layout/PublicLayout";
import PrivatePage from "../app/Page/PrivatePage";

NotFoundErrorPage.propTypes = {};

/**
 * This page is rendered as a standalone page when the router doesn't match a router
 * but also by the RouterErrorHandler. when the app fails with a ReactRouterLoader error
 * and the error has a status (or a response.status) code of 404.
 */
export default function NotFoundErrorPage() {
    /**
     * --------------------
     * Get the identity in order to ensure we can link to the dashboard or login
     * --------------------
     */
    const hasIdentity = useSelector(authSelectors.hasIdentity);

    /**
     * --------------------
     * Get the translator for the page title
     * --------------------
     */
    const { t } = useTranslation();

    const Layout = hasIdentity ? PrivateLayout : PublicLayout;

    return (
        <Layout>
            <PrivatePage title={t("PrivatePage not found")}>
                <div className="px-4 py-10 text-center sm:px-6 lg:px-8">
                    <h1 className="block text-7xl font-bold text-black dark:text-gray-50 sm:text-9xl">404</h1>
                    <h1 className="block text-2xl font-bold text-gray-50"></h1>
                    <p className="mt-3 text-gray-600 dark:text-gray-400">
                        <Trans>Oops, ceva a mers greșit.</Trans>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <Trans>Ne pare rău, nu am putut găsi pagina căutată</Trans>
                    </p>
                </div>
            </PrivatePage>
        </Layout>
    );
}
