import { useStore } from "react-redux";
import { createElement } from "react";
import { authSelectors } from "../redux/auth";
import { redirect } from "react-router-dom";
import { generatePath } from "react-router";
import routes from "../features/router/routes";

export default function useCreatePublicLazyLoader(i18n) {
    const store = useStore();

    return function createPublicLazyLoader(lazyComponentLoader) {
        return async function () {
            const ref = {
                current: null,
            };

            return {
                Component: (props) => createElement(ref.current, props),
                loader: async (opts) => {
                    if (opts.request.signal.aborted) {
                        return null;
                    }

                    if (authSelectors.hasIdentity(store.getState())) {
                        throw redirect(generatePath(routes.INVOICES, opts.params));
                    }

                    let loaderResult = null;
                    const result = await lazyComponentLoader();
                    if (result.loader) {
                        loaderResult = await result.loader(opts, {
                            i18n,
                            dispatch: store.dispatch,
                            getState: store.getState,
                        });
                    }

                    ref.current = result.default;
                    return loaderResult;
                },
            };
        };
    };
}
