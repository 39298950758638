/**
 * !!!!!!!!
 * IMPORTANT: DO NOT FORGET TO ADD / REMOVE / EDIT ROUTES HERE IN BOTH PLACES
 * !!!!!!!!
 */

export const routesParts = {
    // Auth routes
    AUTH_LOGIN: "login",
    AUTH_FORGOT_PASSWORD: "forgot-password",
    AUTH_FORGOT_PASSWORD_FINISH: "forgot-password/finish",
    //routes for the premier-energy application
    INVOICES: "",
    // routes for my account
    MY_ACCOUNT: "my-account",
    MY_ACCOUNT_CHANGE_PASSWORD: "change-password",
};

const routes = {
    // Auth routes
    AUTH_LOGIN: "/login",
    AUTH_FORGOT_PASSWORD: "/forgot-password",
    AUTH_FORGOT_PASSWORD_FINISH: "/forgot-password/finish",
    //routes for the premier-energy application
    INVOICES: "/",
    // routes for my account
    MY_ACCOUNT: "/my-account",
    MY_ACCOUNT_CHANGE_PASSWORD: "/my-account/change-password",
};

export default routes;
