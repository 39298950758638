import React, { createContext, useState } from "react";
import { Root as DropdownPrimitiveRoot } from "@radix-ui/react-dropdown-menu";
import PropTypes from "prop-types";

Dropdown.propTypes = {
    children: PropTypes.node.isRequired,
};

export const DropdownContext = createContext(null);

export default function Dropdown({ children }) {
    const [fadeIn, setFadeIn] = useState(false);

    return (
        <DropdownContext.Provider value={{ fadeIn, setFadeIn }}>
            <DropdownPrimitiveRoot onOpenChange={(isOpen) => setFadeIn(isOpen)}>{children}</DropdownPrimitiveRoot>
        </DropdownContext.Provider>
    );
}
