import { generatePath as _generatePath, useParams } from "react-router-dom";
import { stringify } from "qs";

export default function useGeneratePath() {
    const currentParams = useParams();

    return function innerGeneratePath(to, params, query = {}) {
        const search = stringify(query);
        const newParams = { ...currentParams, ...params };

        to = to + (search ? `?${search}` : "");

        return _generatePath(to, newParams);
    };
}
