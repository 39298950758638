import React, { createContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { I18nextProvider, initReactI18next } from "react-i18next";
import numbro from "numbro";
import i18n from "i18next";
import { setDateFnsLocale } from "./useDateFnsLocale";
import { configActions, configSelectors } from "../../redux/config";

// BUNDLE THE DEFAULT LOCALE IN THE INITIAL CHUNK
const defaultLocale = "ro-RO";
import numbroRO from "numbro/languages/ro-RO";
import { ro as dateFnsRO } from "date-fns/locale";
import appLocaleRO from "../../../resources/lang/ro-RO.json";

export const LanguageLoaderContext = createContext(null);

LanguageLoaderProvider.propTypes = {
    children: PropTypes.node.isRequired,
    thunkOptions: PropTypes.object,
};

export default function LanguageLoaderProvider({ children, thunkOptions }) {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    let locale = defaultLocale;

    const possibleLocales = useSelector(configSelectors.locales);
    if (localStorage.getItem("locale") && possibleLocales.includes(localStorage.getItem("locale"))) {
        locale = localStorage.getItem("locale");
    }

    const debug = useSelector(configSelectors.debug);
    const i18nInstance = useMemo(() => {
        const i18nInstance = i18n.createInstance();
        thunkOptions.i18n = i18nInstance;
        return i18nInstance;
    }, []);

    useEffect(() => {
        dispatch(configActions.changeDefaultLocale(defaultLocale));
    }, []);

    useEffect(() => {
        loadLanguage({ locale, debug, i18n: i18nInstance, thunkOptions }).then(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return null;
    }

    return (
        <LanguageLoaderContext.Provider
            value={(newLocale) => loadLanguage({ locale: newLocale, debug, i18n: i18nInstance, thunkOptions })}>
            <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
        </LanguageLoaderContext.Provider>
    );
}

export async function loadLanguage({ locale, debug, i18n }) {
    /**
     * Actually load the catalog / dateFns and number intl settings
     */
    console.warn("Loading intl for " + locale + "...");
    const { app: catalog, dateFns, numbro: numbroLocale } = await loadLocales(locale);
    console.warn("Loaded intl for " + locale);

    /**
     * Set the catalog to i18n
     */
    await i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            load: "currentOnly",
            lng: locale,
            resources: {},
            fallbackLng: defaultLocale,
            debug: !debug,
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            returnNull: false,
        });

    numbro.registerLanguage(numbroLocale, true);
    setDateFnsLocale(dateFns);
    i18n.addResourceBundle(locale, "translation", catalog);
    await i18n.changeLanguage(locale);

    localStorage.setItem("locale", locale);
}

async function loadLocales(locale) {
    let dateFnsLoader;
    let appLocalesLoader;
    let numbroLoader;

    try {
        switch (locale) {
            case "en-GB":
                [numbroLoader,  { enGB: dateFnsLoader }, appLocalesLoader] = await Promise.all([
                    import("numbro/languages/en-GB"),
                    import("date-fns/locale"),
                    import("../../../resources/lang/en-GB.json"),
                ]);
                break;
            case defaultLocale:
                numbroLoader = { default: numbroRO };
                appLocalesLoader = { default: appLocaleRO };
                dateFnsLoader = { default: dateFnsRO };
                break;
            default:
                throw new Error(`Invalid locale ${locale}`);
        }
    } catch (e) {
        console.error(e);
        numbroLoader = { default: numbroRO };
        appLocalesLoader = { default: appLocaleRO };
        dateFnsLoader = { default: dateFnsRO };
    }

    return {
        dateFns: dateFnsLoader.default,
        app: appLocalesLoader.default,
        numbro: numbroLoader.default,
    };
}
