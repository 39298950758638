import React from "react";
import PropTypes from "prop-types";
import handleReportError from "./handleReportError";
import MaintenanceErrorPage from "./MaintenanceErrorPage";
import InternalServerErrorPage from "./InternalServerErrorPage";

ApplicationErrorHandler.propTypes = {
    error: PropTypes.object.isRequired,
};

export default function ApplicationErrorHandler({ error }) {
    handleReportError(error);

    let isMaintenance = false;

    if (error.status === 503 || (error.response && error.response.status === 503)) {
        isMaintenance = true;
    }

    return (
        <div>
            <header className="z-50 mb-auto flex w-full justify-center py-4">
                <nav className="px-4 sm:px-6 lg:px-8" aria-label="Global">
                    <div className="flex-none text-xl font-semibold dark:text-white sm:text-3xl">Premier Energy</div>
                </nav>
            </header>
            <main id="content" role="main">
                {isMaintenance && <MaintenanceErrorPage />}
                {!isMaintenance && <InternalServerErrorPage />}
            </main>
        </div>
    );
}
