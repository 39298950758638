import React, { useEffect } from "react";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import cc from "classcat";
import { useSelector } from "react-redux";
// components
import Navbar from "../Navbar";
// hooks
import { useApplicationLoaderContext } from "../ApplicationLoaderContext";
import useNavigate from "../../../vendor/router/useNavigate";
// redux
import { authSelectors } from "../../../redux/auth";
// defs
import routes from "../../router/routes";

PrivateLayout.propTypes = {
    children: PropTypes.node,
};

export default function PrivateLayout({ children = null }) {
    const { setIsPageLoaderShown } = useApplicationLoaderContext();

    const navigate = useNavigate();
    const hasIdentity = useSelector(authSelectors.hasIdentity);
    const location = useLocation();

    useEffect(() => {
        setIsPageLoaderShown(false);

        return () => setIsPageLoaderShown(true);
    }, []);

    /**
     * When the user is logged out (either from Navbar > Logout or from interceptor 401 situation)
     * redirect the user to the login page.
     */
    useEffect(() => {
        if (!hasIdentity) {
            navigate(routes.AUTH_LOGIN);
        }
    }, [hasIdentity]);

    /**
     * When the user logs out we don't have any identity and the app can throw errors due to auth selectors.
     * This way we ensure that the app doesn't render anything while the user is redirected
     * to the login page.
     */
    if (!hasIdentity) {
        return null;
    }

    return (
        <>
            <div className="min-h-full">
                <Navbar />
                <main>
                    <div
                        className={cc([
                            "mx-auto py-6 sm:px-6 lg:px-8",
                            {
                                // max width for My account page
                                "max-w-7xl": location.pathname.includes(routes.MY_ACCOUNT),
                            },
                        ])}>
                        {children || <Outlet />}
                    </div>
                </main>
            </div>
            <ScrollRestoration />
        </>
    );
}
