import { memoize } from "proxy-memoize";
import { createReducer } from "@reduxjs/toolkit";

/*******************
 * ACTION TYPES
 *******************/
const actionTypes = { LOAD: "config/LOAD", CHANGE_DEFAULT_LOCALE: "config/CHANGE_DEFAULT_LOCALE" };

/*******************
 * REDUCER
 *******************/
export default createReducer({}, (builder) => {
    builder.addCase(actionTypes.LOAD, (state, action) => {
        return action.payload;
    });
    builder.addCase(actionTypes.CHANGE_DEFAULT_LOCALE, (state, action) => {
        return { ...state, app: { ...state.app, locale: action.payload } };
    });
});

/*******************
 * ACTIONS
 *******************/
export const configActions = {
    loadConfig: function (configData) {
        return (dispatch) => dispatch({ type: actionTypes.LOAD, payload: configData });
    },
    changeDefaultLocale: function (newDefaultLocale) {
        return (dispatch) => dispatch({ type: actionTypes.CHANGE_DEFAULT_LOCALE, payload: newDefaultLocale });
    },
};

/*******************
 * SELECTORS
 *******************/
export const configSelectors = {
    apiHost: memoize((state) => state.config.services.apiHost),
    sentryEnabled: memoize((state) => state.config.services.sentry.enabled),
    sentryDsn: memoize((state) => state.config.services.sentry.dsn),
    sentryReplaysSessionSampleRate: memoize((state) => state.config.services.sentry.replaysSessionSampleRate),
    sentryTracesSampleRate: memoize((state) => state.config.services.sentry.tracesSampleRate),
    env: memoize((state) => state.config.app.env),
    debug: memoize((state) => state.config.app.debug),
    locales: memoize((state) => state.config.app.locales),
    appUrl: memoize((state) => state.config.app.appUrl),
};
