import React from "react";
import PropTypes from "prop-types";
import { useNavigation, useResolvedPath } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import InnerPageLoader from "../InnerPageLoader";

PrivatePage.propTypes = {
    children: PropTypes.node.isRequired,
    disablePageLoader: PropTypes.func,
    title: PropTypes.string,
};

export default function PrivatePage({ title, children, disablePageLoader }) {
    /**
     * By default, always show the page loader
     * unless explicitly told otherwise via "disablePageLoader()"
     */

    const navigation = useNavigation();
    const resolvedPath = useResolvedPath();
    const showLoader =
        disablePageLoader && navigation.state === "loading"
            ? !disablePageLoader(resolvedPath.pathname, navigation.location.pathname)
            : navigation.state === "loading";

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="relative m-3 flex h-full flex-grow flex-col sm:m-0">
                {showLoader ? <InnerPageLoader /> : children}
            </div>
        </>
    );
}
