import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/auth";
import PropTypes from "prop-types";
import { LanguageLoaderContext } from "../intl/LanguageLoaderProvider";
import useLocale from "../intl/useLocale";

AuthManager.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function AuthManager({ children }) {
    const currentLocale = useLocale();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const loadNewLanguage = useContext(LanguageLoaderContext);

    if (error) {
        //* Error boundaries do not catch errors for:
        // Event handlers
        //! Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
        // Server side rendering
        // Errors thrown in the error boundary itself (rather than its children)
        throw error;
    }

    useEffect(() => {
        dispatch(authActions.getIdentity())
            .then(async ({ locale }) => {
                if (currentLocale !== locale) {
                    await loadNewLanguage(locale);
                }
            })
            .catch((err) => {
                /*
                 * Do not throw the error if the error code is 401 (since it just means the user is not logged in)
                 */
                if (err.response && err.response.status === 401) {
                    return;
                }

                setError(err);
            })
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return null;
    }

    return children;
}
