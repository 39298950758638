import React from "react";
import Trans from "../intl/Trans";

InternalServerErrorPage.propTypes = {};

export default function InternalServerErrorPage() {
    return (
        <div className="px-4 py-10 text-center sm:px-6 lg:px-8">
            <h1 className="block text-7xl font-bold text-gray-800 dark:text-white sm:text-9xl">500</h1>
            <h1 className="block text-2xl font-bold text-gray-50"></h1>
            <p className="mt-3 text-gray-600 dark:text-gray-400">
                <Trans>Ups, ceva a mers prost.</Trans>
            </p>
            <p className="text-gray-600 dark:text-gray-400">
                <Trans>Internal Server Error</Trans>
            </p>
        </div>
    );
}
