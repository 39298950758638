import React, { useContext } from "react";
import { I18nContext } from "react-i18next";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import RoutesContextProvider from "../../vendor/router/RoutesContextProvider";
import NotFoundErrorPage from "../errors/NotFoundErrorPage";
import RouterErrorHandler from "../errors/RouterErrorHandler";
import PublicLayout from "../app/Layout/PublicLayout";
import routes, { routesParts } from "./routes";
import Navigate from "../../vendor/router/Navigate";
import useCreatePublicLazyLoader from "../../hooks/useCreatePublicLazyLoader";
import useCreatePrivateLazyLoader from "../../hooks/useCreatePrivateLazyLoader";
import PrivateLayout from "../app/Layout/PrivateLayout";

export default function Router() {
    const { i18n } = useContext(I18nContext);

    /**
     * These 3 (lazy)loader creator functions ensure that:
     * 1) if there is an error already in the loaders chain, they do not continue
     * 2) if the user is / is not authenticated, they redirect to the proper location (login/dash)
     */
    const createPublicLazyLoader = useCreatePublicLazyLoader(i18n);
    const createPrivateLazyLoader = useCreatePrivateLazyLoader(i18n);

    return (
        <RoutesContextProvider routes={routes}>
            <RouterProvider
                router={createBrowserRouter(
                    createRoutesFromElements(
                        <Route ErrorBoundary={RouterErrorHandler}>
                            <Route
                                path=".well-known/change-password"
                                element={() => <Navigate to={routesParts.AUTH_FORGOT_PASSWORD} />}
                            />
                            <Route element={<PrivateLayout />}>
                                <Route
                                    path={routesParts.INVOICES}
                                    lazy={createPrivateLazyLoader(() =>
                                        import("../invoices/ListInvoicesPage"),
                                    )}></Route>

                                <Route
                                    path={routesParts.MY_ACCOUNT}
                                    lazy={createPrivateLazyLoader(() => import("../myAccount/MyAccountPage"))}>
                                    <Route
                                        path={routesParts.MY_ACCOUNT_CHANGE_PASSWORD}
                                        lazy={createPrivateLazyLoader(() =>
                                            import("../myAccount/changePassword/ChangePasswordModal"),
                                        )}
                                    />
                                </Route>
                            </Route>

                            <Route element={<PublicLayout />}>
                                <Route
                                    path={routesParts.AUTH_LOGIN}
                                    lazy={createPublicLazyLoader(() => import("../auth/LoginPage"))}
                                />
                                <Route
                                    path={routesParts.AUTH_FORGOT_PASSWORD}
                                    lazy={createPublicLazyLoader(() =>
                                        import("./../auth/ForgotPasswordPages/StartForgotPasswordPage"),
                                    )}
                                />
                                <Route
                                    path={routesParts.AUTH_FORGOT_PASSWORD_FINISH}
                                    lazy={createPublicLazyLoader(() =>
                                        import("./../auth/ForgotPasswordPages/FinishForgotPasswordPage"),
                                    )}
                                />
                            </Route>

                            <Route path="*" element={<NotFoundErrorPage />} />
                        </Route>,
                    ),
                )}
            />
        </RoutesContextProvider>
    );
}
