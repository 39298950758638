import { Navigate as _Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { stringify } from "qs";
import React from "react";
import useGeneratePath from "./generatePath";

Navigate.propTypes = {
    to: PropTypes.string.isRequired,
    params: PropTypes.object,
    query: PropTypes.object,
};

export default function Navigate({ to, params = {}, query = {}, ...props }) {
    const generatePath = useGeneratePath();
    const search = stringify(query);
    const pathname = generatePath(to, params);

    return <_Navigate {...props} to={{ pathname, search }} />;
}
