import React, { useContext } from "react";
import { Trigger as DropdownPrimitiveTrigger } from "@radix-ui/react-dropdown-menu";
import PropTypes from "prop-types";
import { DropdownContext } from "./Dropdown";

DropdownTrigger.propTypes = {
    children: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default function DropdownTrigger({ children, ...props }) {
    const { fadeIn } = useContext(DropdownContext);

    return (
        <>
            <DropdownPrimitiveTrigger asChild {...props}>
                {children({
                    fadeIn,
                })}
            </DropdownPrimitiveTrigger>
        </>
    );
}
