import React, { useEffect, useState, createContext } from "react";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";

export const IsMobileContext = createContext(null);

IsMobileContextProvider.propTypes = {
    children: PropTypes.node,
    breakpoint: PropTypes.number,
};

export default function IsMobileContextProvider({ children, breakpoint = 1024 }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);

    useEffect(() => {
        const debounceCallback = debounce(() => {
            setIsMobile(window.innerWidth < breakpoint);
        }, 300);
        window.addEventListener("resize", debounceCallback);

        return () => {
            window.removeEventListener("resize", debounceCallback);
        };
    }, []);

    return <IsMobileContext.Provider value={isMobile}>{children}</IsMobileContext.Provider>;
}
