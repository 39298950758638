import React, { useContext } from "react";
import { NavLink as _NavLink, matchPath, useResolvedPath } from "react-router-dom";
import PropTypes from "prop-types";
import { stringify } from "qs";
import { RoutesContext } from "./RoutesContextProvider";
import useGeneratePath from "./generatePath";

NavLink.propTypes = {
    to: PropTypes.string,
    params: PropTypes.object,
    query: PropTypes.object,
    end: PropTypes.bool,
};

export default function NavLink({ to, params = {}, query = {}, ...props }) {
    const { routes } = useContext(RoutesContext);
    const generatePath = useGeneratePath();
    const resolvedPath = useResolvedPath();
    const search = stringify(query);

    if (!to) {
        for (let pattern of Object.values(routes)) {
            if (matchPath(pattern, resolvedPath.pathname)) {
                to = pattern;
                break;
            }
        }
    }

    const pathname = generatePath(to, params);

    // we allow the navlink to retrigger the navigation change even if
    // if it's the same route so that it closes the sidebar

    return <_NavLink {...props} to={{ pathname, search }} />;
}
