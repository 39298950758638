import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { Provider as ToastProvider, Viewport } from "@radix-ui/react-toast";

export const FlashMessengerContext = createContext(null);

FlashMessengerProvider.propTypes = {
    children: PropTypes.node.isRequired,
    thunkOptions: PropTypes.object,
};

export default function FlashMessengerProvider({ children, thunkOptions }) {
    const [message, setMessage] = useState(null);

    function setSuccessMessage({ message, duration = 5000 }) {
        setMessage({ message, type: "success", duration });
    }
    function setErrorMessage({ message, duration = 5000 }) {
        setMessage({ message, type: "error", duration });
    }

    function removeMessage() {
        setMessage(null);
    }

    // We do this so we can use flash messages outside react components too
    thunkOptions.setErrorMessage = setErrorMessage;

    return (
        <ToastProvider>
            <FlashMessengerContext.Provider
                value={{
                    setSuccessMessage,
                    setErrorMessage,
                    removeMessage,
                    message,
                }}>
                {children}
                <Viewport className="fixed right-0 top-0 z-50 w-full p-3 sm:w-[500px]" />
            </FlashMessengerContext.Provider>
        </ToastProvider>
    );
}
