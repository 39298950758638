import React, { useEffect, useState } from "react";
import Spinner from "../uikit/Spinner";

export default function InnerPageLoader() {
    const [show, setShow] = useState(false);

    /**
     * --------------------
     * Ensure that the spinner is shown ONLY after 50ms (value chosen by common-sense)
     * since otherwise we see the spinner EVEN if the component was previously loaded.
     * --------------------
     */
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, 50);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (!show) {
        return null;
    }

    return (
        <div className="flex h-full flex-col">
            <div className="shadow-md flex flex-col items-center justify-center rounded-xl bg-stone-50 p-5 py-32 text-stone-950 dark:bg-stone-800 dark:text-stone-200 md:p-7 md:py-40">
                <Spinner size="large" variant="primary" />
            </div>
        </div>
    );
}
