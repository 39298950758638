import React from "react";
import Fade from "./Fade";
import CentralLoader from "../uikit/CentralLoader";
import PropTypes from "prop-types";

FullPageLoader.propTypes = {
    open: PropTypes.bool,
};

export default function FullPageLoader({ open = true }) {
    return (
        <Fade in={open} initiallyShown={true} fadeSpeed={100}>
            <div className="absolute z-50 h-screen w-screen">
                <CentralLoader />
            </div>
        </Fade>
    );
}
