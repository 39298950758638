import axios from "axios";
import { authActions, authSelectors } from "../redux/auth";
import { configSelectors } from "../redux/config";

httpClientProvider.store = null;

export default function httpClientProvider(store) {
    /**
     * Create the main HTTP Client
     */
    const httpClient = axios.create({
        withCredentials: true,
        withXSRFToken: (config) => config.url.includes(configSelectors.apiHost(store.getState())),
    });

    /**
     * Ensure that when an error occurs, if it's a 401 error, we set identity to null so the use effect
     *  from privateLayout will redirect to login and we show the toaster (here to avoid showing it after logout)
     */
    httpClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (!error.response) {
                return Promise.reject(error);
            }

            /**
             * If a request is made and fails with 401 and the user is logged in (meaning he made a request either by navigating
             * OR by submitting a form or just a custom API call...) then show the toaster expiration message + clear identity.
             * Since the user was logged in, the Private Layout will redirect the user to the login page.
             */
            if (error.response.status === 401 && authSelectors.hasIdentity(store.getState())) {
                store.dispatch((dispatch, getState, thunkOptions) => {
                    thunkOptions.setErrorMessage({
                        message: thunkOptions.i18n.t("Sesiunea a expirat."),
                    });
                });
                store.dispatch(authActions.setIdentity(null));
            }

            return Promise.reject(error);
        },
    );

    return httpClient;
}
