import { useLocation, useNavigate as _useNavigate } from "react-router-dom";
import { stringify } from "qs";
import useGeneratePath from "./generatePath";

export default function useNavigate() {
    const location = useLocation();
    const _navigate = _useNavigate();
    const generatePath = useGeneratePath();

    return function navigate(to, params = {}, query = {}) {
        const search = stringify(query);
        const pathname = generatePath(to, params);
        // console.log("navigate test");
        // console.log(pathname);
        if (location.pathname === pathname && location.search === search) {
            // console.log("return test");
            return;
        }

        return _navigate({
            pathname,
            search,
        });
    };
}
